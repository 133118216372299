import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import setAuthToken from '../utils/setAuthToken';

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  if (axios.defaults.headers.hasOwnProperty('common')) {
    if (!axios.defaults.headers.common.hasOwnProperty('Authorization')) {
      if (localStorage.jwtToken) {
        const token = localStorage.jwtToken;
        setAuthToken(token);

      }
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        user.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
};

export default connect(state => state)(PrivateRoute);