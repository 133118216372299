import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import { Avatar, Card, Col, Divider, Form, Input, Menu, Result, Row, Select, Space, Spin } from 'antd';
import { EditOutlined, EllipsisOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { getApps } from '../actions';

class Home extends Component {
  state = {
    loading: true,
    query: 'all',
    orderBy: 'orderName',
    search: '',
    retry: false,
    data: []
  };

  async componentDidMount() {
    const { apps, dispatch } = this.props
    const { user } = this.props.user
    if (['ftp'].includes(user.role)) {
      this.props.history.push('/bases');
    } else if (window.location.search.includes('proxyRestUri')) {
      this.setState({retry: true})
      axios.get('/api/qlik-session')
      .then(() => {
        window.location = window.location.pathname
      })
      .catch(error => console.log(error))
    } else if (apps.length === 0) {
      async function getAllApps() {
        try {
          const qlikAxios = axios.create();
          qlikAxios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
          delete qlikAxios.defaults.headers.common['Authorization'];
          const response = await qlikAxios.get('https://app.inovyo.com/qrs/app/hublist/full', {
            params: {
              xrfkey: user.xrf
            },
            headers: {
              'Content-Type': 'application/json',
              'X-Qlik-Xrfkey':  user.xrf
            },
            redirect: 'follow',
            withCredentials: true
          })
          return response.data
        } catch (err) {
          window.location = window.location + '?proxyRestUri=https%3a%2f%2finovyo%3a4243%2fqps%2f'
          return err
        }
      }

      getAllApps()
      .then(res => {
        const data = []
        res.forEach( item => {
          const arr = '{"'+item.description.replace(/;/g,'","').replace(/: /g,'":"').replace(/\n/g,'')+'"}';
          item.name = item.name.replace('_CX','');
          item.orderName = item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
          item.appStream = item.stream !== null ? item.stream.name.toLowerCase() : 'unpublished';
          if (item.description.includes('productType')) {
            item.description = JSON.parse(JSON.parse(JSON.stringify(arr)));
            item.qDirectory = item.description.qDirectory.substring(3);
            item.qDivImage = item.description.qDivImage.replace('./images/','');
            item.qDivision = item.description.qDivision.replace('<b>','').replace('</b>','');
            item.productType = item.description.productType.replace('<b>','').replace('</b>','');
            ['customProperties', 'owner', 'sourceAppId', 'targetAppId', 'tags', 'description', 'stream', 'fileSize', 'lastReloadTime', 'thumbnail', 'savedInProductVersion', 'migrationHash', 'dynamicColor', 'availabilityStatus', 'privileges', 'schemaPath'].map(key => delete item[key])
            item.published === true && data.push(item)
          }
        })
        dispatch(getApps(data))
        this.setState({loading: false, data: data})
      })
      .catch(err => {
        this.setState({loading: false})
      });
    } else {
      this.setState({loading: false, data: apps})
    }
  }

  reloadApp = appId => {
    const { user } = this.props.user;
    const qlikReload = axios.create({
      baseURL: "https://app.inovyo.com/qrs/app/",
      withCredentials: true,
      headers: {
        'X-Qlik-Xrfkey':  user.xrf
      }
    });
    qlikReload.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    delete qlikReload.defaults.headers.common['Authorization'];

    qlikReload.post(`${appId}/reload?xrfkey=${user.xrf}`)
    .then(res => console.log(JSON.stringify(res.data)))
    .catch(err => console.error(JSON.stringify(err)));
  }

  render() {
    const { Option } = Select;
    const { Meta } = Card;
    const { SubMenu } = Menu;
    const { data, loading, query, retry } = this.state;
  
    const handleCategory = value => {
      this.setState({query: value})
    }

    const handleOrder = value => {
      this.setState({orderBy: value})
    }

    const handleSearch = event => {
      const { search } = this.state
      const { apps } = this.props
      this.setState({search: event.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()})
      const filterApps = apps.filter(app => app.orderName.includes(search))
      this.setState({data: filterApps})
    }

    const renderApp = (data, query) => {
      const { orderBy } = this.state;
      const rows = _.chunk(
        data
          .sort( (a,b) => {
            let x = a[orderBy];
            let y = b[orderBy];
            if (orderBy === 'orderName') {
              return x < y ? -1 : x > y ? 1 : 0
            } else {
              return y < x ? -1 : y > x ? 1 : 0;
            }
          })
          .filter(app => query !== 'all' ? app.productType === query : app)
      , window.innerWidth > 767 ? 3 : 2);
      const content = rows.map( row => {
        return(
          <Row gutter={[16, 16]}>
            {
              row.map( (item) => {
                return(
                  <Col xs={24} sm={12} md={8}>
                    <Card style={{ width: '100%' }} hoverable>
                      <a style={{textDecoration: 'none', color: 'inherit'}} href={`https://app.inovyo.com/extensions/${item.qDirectory}`}>
                        {this.props.user.user.role === 'admin' &&
                          <Menu mode="horizontal" className="app-options">
                            <SubMenu title={<EllipsisOutlined />}>
                              <Menu.Item><EditOutlined /> <a target="_blank" rel="noopener noreferrer" href={`https://app.inovyo.com/sense/app/${item.id}`}>Abrir App</a></Menu.Item>
                              <Menu.Item onClick={() => this.reloadApp(item.id)}><ReloadOutlined /> Recarregar informações</Menu.Item>
                            </SubMenu>
                          </Menu>
                        }
                        <Meta
                          avatar={ <Avatar size={64} className={item.appStream === 'inativos' && 'bewImg'} src={`../assets/dash/${item.qDivImage}`} /> }
                          title={item.name}
                          description={item.qDivision}
                        />
                      </a>
                    </Card>
                  </Col>
                )
              })
            }
          </Row>
        )
      })
      return(
        <div>
          {content}
        </div>
      )
    }

    return (
      <div>
        <Form>
          <Row gutter={16}>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Buscar"
                name="search"
                style={{ display: 'inline-block', width: '100%', marginBottom: 0 }}
              >
                <Input placeholder="Buscar dashboard" onChange={handleSearch} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Filtrar por categoria"
                name="category"
                style={{display: 'inline-block', width: '100%', marginBottom: 0}}
              >
                <Select name="category" id="category" defaultValue="all" onChange={handleCategory}>
                  <Option value="all">Todas</Option>
                  <Option value="ad">Adhoc</Option>
                  <Option value="bx">Before Experience</Option>
                  <Option value="cx">Customer Experience</Option>
                  <Option value="ex">Employee Experience</Option>
                  <Option value="isc">Inovyo Survey Club</Option>
                  <Option value="kn">Knowtics</Option>
                  <Option value="rt">Real Time</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Ordenar por"
                name="order"
                style={{display: 'inline-block', width: '100%', marginBottom: 0}}
              >
                <Select name="order" id="order" defaultValue="orderName" onChange={handleOrder}>
                  <Option value="orderName">Nome</Option>
                  <Option value="modifiedDate">Data de Modificação</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider />
        {loading
        ? <Space align="center" direction="vertical" style={{width: '100%'}}><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></Space>
        : !loading && data.length > 0
          ? renderApp(data, query)
          : retry
            ? <Result
              status="warning"
              title="Ocorreu um erro ao listar seus dashboards"
              subTitle="Aguarde por favor, uma nova tentativa será realizada"
            />
            : <Result
              title="Ainda não existe dashboard publicado em sua conta"
              subTitle="Tente novamente mais tarde"
            />
        }  
      </div>
    )
  }
}

export default connect(state => state)(Home);
