import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Form, Input, message } from 'antd';
import { UserOutlined, ArrowLeftOutlined, CheckCircleFilled } from '@ant-design/icons';

class RecoverPass extends Component {
    state = {
      email: '',
      status: '',
      code: '',
      step: 1
    }
  
    handleChange = e => {
      let data = {}
      const key = e.target.name;
      const value = e.target.value;
      data[key] = value;
      this.setState(data);
    };
  
    handleSubmit = () => {
      message.loading({ content: 'Verificando o e-mail', key: 'recover', duration: 0 });
  
      axios({
        method: 'post',
        url: '/api/redefinir-senha',
        data: JSON.stringify({email: this.state.email})
      })
      .then(result => {
        this.setState({step: 2, status: result.data.status})
        if(this.state.status === 'success') {
          message.success({ content: 'Código de verificação enviado, confira seu e-mail', key: 'recover' })
        } else {
          message.error({ content: 'Falha ao solicitar o resgate de senha, tente novamente', key: 'recover' })
        }
      })
      .catch( err => {
        if (err.response.data.hasOwnProperty('detail')) {
          message.error({ content: err.response.data.detail, key: 'recover' })
        } else {
          message.error({ content: 'Ocorreu um erro, tente novamente mais tarde', key: 'recover' })
        }
      })
    };

  render() {
    const { step, status } = this.state;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };

    const handleSubmitCode = async () => {
      const {email, code} = this.state
      const hide = message.loading({ content: 'Verificando código', key: 'code', duration: 0 });

      await axios({
        method: 'post',
        url: '/api/verificar-codigo',
        data: JSON.stringify({email, code})
      }).then(result => {
        hide()
        const {status} = result.data;
        if(status === 'success') {
          message.success({ content: 'Código verificado com sucesso', key: 'code' })
          this.props.history.push({
            pathname: "/nova-senha",
            state: {
              email,
              code
            }
          })
        } else {
          message.error({ content: 'Código inválido, tente novamente', key: 'recover' })
        }
      }).catch(() => {
        hide()
        message.error({ content: 'Ocorreu um erro, tente novamente mais tarde', key: 'recover' })
      })
    }

    return(
      <div className="space-align-container">
        <Card className="space-align-block">
          <Form
            {...layout}
            name="login"
            initialValues={{ remember: true }}
          >
            <Form.Item>
              <div className="logo" />
            </Form.Item>

            <div>
              <Form.Item
                label="Por favor, insira seu e-mail abaixo"
                name="email"
                rules={[{ required: true, message: 'Por favor, insira seu e-mail!' }]}
              >
                <Input style={{width: '100%'}} prefix={<UserOutlined />} id="email" name="email" onChange={this.handleChange} placeholder="E-mail" />
              </Form.Item>
            </div>
            {step === 2 && status === 'success' &&
               <div>
                <Form.Item
                  label="Por favor, insira o código de verificação"
                  name="code"
                  rules={[{ required: true, message: 'Por favor, insira seu código de verificação!' }]}
                >
                  <Input style={{width: '100%'}} prefix={<CheckCircleFilled />} id="code" name="code" onChange={this.handleChange} placeholder="Código de verificação" />
                </Form.Item>
              </div>
            }

            {step === 1 || status === '' || status === 'error' ?
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.handleSubmit}
                >
                REDEFINIR SENHA
                </Button>
              </Form.Item> : <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => handleSubmitCode()}
                >
                  CONFIRMAR
                </Button>
              </Form.Item>
            }

            <Form.Item>
              <Button type="link" onClick={() => this.props.history.goBack()}><ArrowLeftOutlined /> Voltar</Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    )
  }
}

export default withRouter(RecoverPass);