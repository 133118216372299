import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import axios from 'axios';
import { Button, Layout, Menu, message, Modal, Popconfirm, Table, Tag, Tooltip, Typography } from 'antd';
import { DownloadOutlined, DeleteFilled, ProfileOutlined, UploadOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { getAllFiles, delFile } from '../actions'

class Bases extends Component {
  state = {
    data: [],
    modalTitle: '',
    loading: false,
    visible: false,
    resultsOnPage: 10,
    collapsed: window.innerWidth > 768 ? false : true
  };

  componentDidMount() {
    moment.locale("pt-br");
    const { dispatch, files } = this.props;
    const { user } = this.props.user;
    if (!files.reload && files.data.length > 0) {
      this.setState({data: files.data, loading: false})
    } else {
      this.setState({loading: true})

      const getSurveys = axios.get(`/api/pesquisas/${user.user}`);
      const getFiles = axios.get(`/api/bases/${user.user}`);
      axios.all([getSurveys, getFiles])
      .then(axios.spread((...responses) => {
        const surveys = responses[0].data.data;
        const fetchfiles = responses[1].data.files;
        const data = [];
        fetchfiles.forEach(item => {
          data.push({
            id: item.id,
            userId: item.user,
            bounce: item.bounce ? parseInt(item.bounce) : '-',
            contacts: item.contacts ? parseInt(item.contacts) : '-',
            createdat: moment(item.createdat).format('DD/MM/YY HH:mm'),
            timestamp: moment(item.createdat).format('x'),
            filename: item.filename,
            status: item.status,
            surveyId: item.surveyid,
            survey: item.surveyid !== 1 ? surveys.filter( cid => cid.id === item.surveyid).map(item => item.name) : 'OUTROS'
          })
        })
        dispatch(getAllFiles(data))
        this.setState({data: data, loading: false})
      }))
      .catch(err => this.setState({loading: false}))
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleClick = e => {
    this.props.history.push(e.key)
  };

  render() {
    const { Title, Text } = Typography;
    const { Content, Sider } = Layout;
    const { data, loading } = this.state;
    const { user } = this.props.user;

    const tableChanges = e => {
      if (e.pageSize !== this.state.resultsOnPage) {
        this.setState({resultsOnPage: e.pageSize});
      }
    }

    const downloadFile = (file, userId, surveyId) => {
      const { user } = this.props.user;
      message.loading({ content: 'Preparando o seu download', key: 'download', duration: 0})
      axios({
        method: 'post',
        url: '/api/download-base',
        data: JSON.stringify({
          id: user.id,
          preffix: user.user,
          filename: file,
          fileOfUser: userId,
          survey: surveyId
        })
      })
      .then( res => {
        const url = res.data.url;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
        link.remove();
        message.success({ content: 'Concluído, iniciando o download', key: 'download', duration: 0.1})
      })
      .catch(err => {
        message.error({ content: 'Por favor, tente novamente. Ocorreu um erro.', key: 'delete'})
      });
    }

    const deleteFile = (file, id) => {
      const { dispatch } = this.props;
      const { user } = this.props.user;
      const { data } = this.state;
      message.loading({ content: 'Deletando base.', key: 'delete', duration: 0})
      axios({
        url: `/api/deletar-base/${id}`,
        method: 'delete',
        params: {
          preffix: user.user,
          filename: file
        }
      })
      .then(() => {
        dispatch(delFile())
        const updateFile = data.filter(item => id !== item.id)
        this.setState({ data: updateFile})
        message.success({ content: 'Base removida com sucesso.', key: 'delete'})
      })
      .catch(err => {
        message.error({ content: 'Por favor, tente novamente. Ocorreu um erro.', key: 'delete'})
      });
    }

    const columns = [
      {
        title: 'Data de Envio',
        dataIndex: 'createdat',
        sorter: (a, b) => a.timestamp - b.timestamp,
        defaultSortOrder: 'descend',
        width: 130
      },{
        title: 'Ponto de Contato',
        dataIndex: 'survey',
        sorter: (a, b) => {return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0},
        width: 150
      },{
        title: 'Arquivo',
        dataIndex: 'filename',
        sorter: (a, b) => {return (a.filename < b.filename) ? -1 : (a.filename > b.filename) ? 1 : 0},
        width: 150,
      },{
        title: 'Qtd. de Contatos',
        dataIndex: 'contacts',
        sorter: (a, b) => a.contacts - b.contacts,
        width: 100
      },{
        title: 'E-mails inválidos',
        dataIndex: 'bounce',
        sorter: (a, b) => a.bounce - b.bounce,
        width: 100
      },{
        title: 'Status',
        dataIndex: 'status',
        render: item => {
          let color = item === 'waiting' ? '#999999' : item === 'processing' ? '#ACE8FF' : item === 'success' ? '#8FCA8F' : '#D1807D';
          return (
            <Tag color={color} key={item}>
              {item === 'waiting' ? 'Na fila' : item === 'processing' ? 'Processando' : item === 'success' ? 'Sucesso' : 'Falha'}
            </Tag>
          )
        },
        sorter: (a, b) => a.status - b.status,
        width: 100,
        key: 5
      },{
        title: 'Ações',
        render: (row) => {
          return(
            <div>
              <Tooltip title="Download da base">
                <Button
                  disabled={['demo','sale'].includes(user.role)}
                  shape="circle"
                  value="small"
                  onClick={() => downloadFile(row.filename, row.userId, row.surveyId)}
                  icon={<DownloadOutlined />}
                  style={{marginRight: 12}}></Button>
              </Tooltip>
              <Tooltip title="Deletar base">
                <Popconfirm title={`Deletar a base ${row.filename}?`} okText="Sim" cancelText="Não" placement="bottomRight" onConfirm={() => deleteFile(row.filename, row.id)} disabled={['demo','sale'].includes(user.role) ? true : row.status === 'waiting' ? false : true}>
                  <Button
                    disabled={['demo','sale'].includes(user.role) ? true : row.status === 'waiting' ? false : true}
                    shape="circle"
                    value="small"
                    icon={<DeleteFilled />}></Button>
                </Popconfirm>
              </Tooltip>
            </div>
          )
        },
        fixed: 'right',
        width: 110,
        key: 6
      }
    ];

    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed} width={200}>
          <Menu
            onClick={this.handleClick}
            theme="light"
            style={{ height: '100%' }}
            defaultSelectedKeys={['bases']}
          >
            <Menu.Item key="bases"  icon={window.innerWidth < 769 && <ProfileOutlined />}>Histórico</Menu.Item>
            {!['sale'].includes(user.role) && <Menu.Item key="enviar-base"  icon={window.innerWidth < 769 && <UploadOutlined />}>Enviar Base</Menu.Item>}
          </Menu>
        </Sider>
        <Content className="site-layout" style={{ padding: 0, margin: 0, minHeight: 440, background: 'white' }}>
          <div className="site-layout-background" style={{ padding: 16, paddingTop: 0 }}>
            <Title level={4}>
            {window.innerWidth < 769 && React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}
            Bases</Title>
            <Table
              responsive={true}
              columns={columns}
              dataSource={data}
              pagination={{ pageSize: this.state.resultsOnPage, showSizeChanger: true, size: 'small' }}
              onChange={(e) => tableChanges(e)}
              scroll={{ y: 380 }}
              loading={loading} />
          </div>
        </Content>
        <Modal
          title={this.state.modalTitle}
          visible={this.state.visible}
          onOk={() => this.setState({visible: false})}
          onCancel={() => this.setState({visible: false})}
        >
          <Text>Some contents...</Text>
        </Modal>
      </Layout>
    )
  }
}

export default connect(state => state)(withRouter(Bases));