/* const json = { //Dados virão da API com base no hash ID do cliente
  clientName: "Alphaville",
  clientLogo: "url da da logo",
  clientId: "fewfew-fewf-fewfew-fewf",
  pages: [ //a ordem das páginas, será a ordem do menu da página
    {
      iconPage: "url do icone || nome do icone",
      pageName: "Perfil",
      pageId: "fewfew-fewf-fewfew-fewf",
      visible: 1, // 1 = visível / 0 = não visível
      group: [ //a ordem dos grupos, será a ordem deles na página
        {
          groupName: "nome do grupo",
          groupId: "fewfew-fewf-fewfew-fewf", // id aleatorio do grupo
          dashs: [ //a ordem dos dashboards, será a ordem deles dentro do grupo
            {
              dashName: "Nome do dashboard",
              dashId: "fewfew-fewf-fewfew-fewf", 
              dashType: "tipo do dash",
              filter: "",
              data: null // recebe o json {} de acordo com a library usada (google, chartjs, etc..)
            }
          ]
        }
      ]
    }
  ]
}; */

import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { v4 } from "uuid";

//styles
import "./style.css";

const DashboardPage = () => {
  const dashStorage = localStorage.getItem("BASE_DASH") || "";

  const dashBase = {
    clientName: "",
    clientLogo: "",
    clientId: "",
    pages: [],
  };

  const [structure, setStructure] = useState(dashBase);

  useEffect(() => {
    if (dashStorage === "") {
      localStorage.setItem("BASE_DASH", JSON.stringify(dashBase));
    } else {
      localStorage.setItem("BASE_DASH", dashStorage);
      setStructure(JSON.parse(dashStorage));
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.setItem("BASE_DASH", JSON.stringify(structure));
  }, [structure]);

  const insertPage = () => {
    let aux = structure;
    aux.pages.push({ pageName: "", pageId: v4() });

    setStructure({
      ...structure,
      aux,
    });
  };

  const deletePage = (id) => {
    let aux = structure;
    aux.pages.splice(id, 1);

    setStructure({
      ...structure,
      aux,
    });
  };

  return (
    <div id="page-create-board">
      <div className="divisor">
        {structure.pages.length > 0 &&
          structure.pages.map((page, index) => {
            return (
              <div className="page-box" key={v4()}>
                <div className="close-page" onClick={() => deletePage(index)}>
                  x
                </div>
                <h3 className="title-page">Página {index + 1}</h3>
              </div>
            );
          })}
        <Button type="primary" size={"large"} onClick={() => insertPage()}>
          ADICIONAR PÁGINA
        </Button>
      </div>
    </div>
  );
};

export default DashboardPage;
