import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import _ from 'lodash';
import { Layout, Menu, Table, Typography } from 'antd';
import { OrderedListOutlined, PlusCircleOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { getSurveys } from '../actions';

const listClients = []

class Survey extends Component {
  state = {
    resultsOnPage: 10,
    loading: true,
    data: [],
    collapsed: window.innerWidth > 768 ? false : true
  };

  componentDidMount() {
    const { user } = this.props.user;
    const { dispatch, surveys } = this.props;
    
    if (!surveys.reload && surveys.data.length > 0) {
      this.setState({data: surveys.data, loading: false})
      surveys.data.forEach(item => {
        listClients.push({
          text: item.client,
          value: item.client
        })
      })
    } else {
      axios.get(`/api/pesquisas/${user.user}`)
      .then(res => {
        const surveys = []
        res.data.data.forEach( item => {
          listClients.push({
            text: item.client,
            value: item.client
          })
          surveys.push({
            id: item.id,
            client: item.client,
            category: item.type,
            name: item.name,
	    customfields: item.customfield,
            status: item.status === 'A' ? 'Ativo' : 'Inativo'
          })
        })
        dispatch(getSurveys(surveys))
        this.setState({data: surveys, loading: false})
      })
      .catch(err => this.setState({loading: false}, () => console.log(err)))
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { Content, Sider } = Layout;
    const { Title } = Typography;
    const { data, loading } = this.state;
    const { user } = this.props.user;

    const tableChanges = e => {
      if (e.pageSize !== this.state.resultsOnPage) {
        this.setState({resultsOnPage: e.pageSize});    
      }
    }

    const handleClick = e => {
      this.props.history.push(e.key)
    };

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
        width: 80
      }, {
        title: 'Ponto de Contato',
        dataIndex: 'name',
        sorter: (a, b) => {return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0},
      }, {
        title: 'Cliente',
        dataIndex: 'client',
        filters: _.uniqBy(listClients, 'value'),
        onFilter: (value, row) => row.client.indexOf(value) === 0
      }, {
        title: 'Categoria',
        dataIndex: 'category',
        filters: [
          { text: 'Ad Hoc', value: 'AH' },
          { text: 'Customer Experience', value: 'CX' },
          { text: 'Knowtics', value: 'KN' },
          { text: 'Real Time', value: 'RT' }
        ],
        onFilter: (value, row) => row.category.indexOf(value) === 0
      }, {
        title: 'Status',
        dataIndex: 'status',
        filters: [
          { text: 'Ativo', value: 'Ativo' },
          { text: 'Inativo', value: 'Inativo' }
        ],
        onFilter: (value, row) => row.status.indexOf(value) === 0
      }
    ];

    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed} width={200}>
          <Menu
            onClick={handleClick}
            style={{ height: '100%' }}
            defaultSelectedKeys={['pesquisas']}
          >
            <Menu.Item key="pesquisas" icon={window.innerWidth < 769 && <OrderedListOutlined />}>Todos as Pesquisas</Menu.Item>
            {['admin','customersuccess'].includes(user.role) &&  <Menu.Item key="adicionar-pesquisa" icon={window.innerWidth < 769 && <PlusCircleOutlined />}>Adicionar pesquisa</Menu.Item>}
          </Menu>
        </Sider>
        <Content className="site-layout" style={{ padding: 0, margin: 0, minHeight: 440, background: 'white' }}>
          <div className="site-layout-background" style={{ padding: 16, paddingTop: 0 }}>
            <Title level={4}>
            {window.innerWidth < 769 && React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}
            Pesquisas</Title>
            <Table
              responsive={true}
              columns={_.remove(columns, function(n) {
                  if (['admin','customersuccess','sale'].includes(user.role)) {
                    return n
                  } else {
                    return n.title !== 'Cliente'
                  }
                })
              }
              dataSource={data}
              pagination={{ pageSize: this.state.resultsOnPage, showSizeChanger: true, size: 'small' }}
              onChange={(e) => tableChanges(e)}
              scroll={{ y: 380 }}
              loading={loading} />
          </div>
        </Content>
      </Layout>
    )
  }
}

export default connect(state => state)(withRouter(Survey));
