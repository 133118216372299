import React, { Component } from 'react';
import { Button, Checkbox, Col, Divider, Form, Layout, Menu, message, Result, Row, Select, Steps, Typography, Upload } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import _ from "lodash";
import { getSurveys, addFile } from '../actions';
import { InboxOutlined, ProfileOutlined, UploadOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

const defaultFields = [
  {value: 'name', label: 'Nome'},
  {value: 'email', label: 'E-mail'},
  {value: 'phone', label: 'Telefone'}
];

class SendBase extends Component {
  state = {
    headerChecked: true,
    optinChecked: true,
    current: 0,
    file: [],
    notReady: true,
    loading: true,
    isNotSurvey: false,
    loadingUpload: false,
    selectedFields: [],
    filefields: {},
    mySelections: [],
    surveys: [],
    fields: [],
    collapsed: window.innerWidth > 768 ? false : true
  }

  componentDidMount() {
    const { user } = this.props.user;
    const { dispatch, surveys } = this.props;
    if (['sale'].includes(user.role)) {
      this.props.history.push('/bases');
    }
    if (!surveys.reload && surveys.data.length > 0) {
      this.setState({surveys: surveys.data, loading: false, notReady: false})
    } else {
      axios.get(`/api/pesquisas/${user.user}`)
      .then(res => {
        const surveys = []
        res.data.data.forEach( item => {
          surveys.push({
            id: item.id,
            client: item.client,
            category: item.type,
            name: item.name,
            customfields: item.customfield,
            status: item.status === 'A' ? 'Ativo' : 'Inativo'
          })
        })
        dispatch(getSurveys(surveys))
        this.setState({surveys: surveys, loading: false, notReady: false})
      })
      .catch(err => this.setState({loading: false, notReady: false}, () => console.log(err)))
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleSelect = (key, value) => {
    const el = {};
    key === 'surveyid' ? el[key] = value : el[value] = key;
    this.setState(el);
  };

  handleAnotherBase = () => {
    this.setState({surveyid: 1, isNotSurvey: !this.state.isNotSurvey});
  };

  handleClick = e => {
    this.props.history.push(e.key)
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  handleUpload = () => {
    const { user } = this.props.user
    const { file, mySelections, isNotSurvey, surveyid, surveys } = this.state;
    const actualsurvey = surveys.filter(survey => surveyid === survey.id);
    const scustomfield = actualsurvey.length > 0 ? actualsurvey[0].customfields : undefined;
    if (scustomfield !== undefined) {
      Object.entries(scustomfield).map(i => i[0] !== 'Telefone' && defaultFields.push({ value: i[0], label: i[0] }))
    }
    const preffix = user.user;
    const formData = new FormData();
    formData.append('file', file);
    this.setState({ loadingUpload: true });
    axios({
      url: `/api/enviar-base/${preffix}`,
      method: 'post',
      data: formData
    })
    .then( res => {
      const el = [];
      res.data.fields.map((i,index) => el[index] = [])
      const selections = _.concat(mySelections, el)
      this.setState({
        fields: res.data.fields,
        rows: isNotSurvey ? 0 : res.data.rows,
        mySelections: selections,
        selectedFields: selections,
        filename: res.data.filename,
        loadingUpload: false
      });
      message.success('Upload concluído');
      isNotSurvey ? this.handleSubmit() : this.next();
    })
    .catch(err => {
      this.setState({loadingUpload: false});
      message.error('Falha no upload');
    });
  };

  handleSelectFields = (key, value, index) => {
    const { selectedFields, mySelections, filefields } = this.state;
    const selection = _.clone(mySelections)
    const newSelections = _.clone(selectedFields)
    if (value !== undefined) {
      if (value.label === "Ignorar campo") {
        selection[index] = "Ignorar campo"
      } else {
        const myKey = value.value;
        filefields[myKey] = key;
        selection[index] = value.label;
        newSelections[index] = value.label;
      }
    } else {
      const toRemove = defaultFields.filter(item => item.label === newSelections[index])[0].value;
      delete filefields[toRemove];
      selection[index] = [];
      newSelections[index] = [];
    }
    this.setState({ filefields: filefields, selectedFields: newSelections, mySelections: selection });
  };

  handleSubmit = () => {
    const { user } = this.props.user;
    const { dispatch } = this.props;
    const { surveys, headerChecked } = this.state;
    const ignoreFields = ['0','current','surveys','fields','file','loading','loadingUpload','isNotSurvey','mySelections','selectedFields','collapsed','headerChecked','optinChecked'];
    this.setState({loadingUpload: true});

    const arr = _.clone(this.state);
    ignoreFields.map(key => arr.hasOwnProperty(key) && delete arr[key]);
    arr['user'] = user.id;
    arr.filefields['header'] = headerChecked;

    axios({
      url: '/api/salvar-base',
      method: 'post',
      data: JSON.stringify(arr)
    })
    .then(res => {
      dispatch(addFile())
      this.setState({
        current: 3,
        file: [],
        fields: [],
        loading: true,
        loadingUpload: false,
        surveys: surveys,
        isNotSurvey: false
      });
    })
    .catch(err => {
      this.setState({loadingUpload: true})
      message.error('Falha ao salvar o mapa dos campos');
    });
  }
  
  onSearch = (val) => {}
  
  render() {
    const { Step } = Steps;
    const { Option } = Select;
    const { Title } = Typography;
    const { Dragger } = Upload;
    const { Sider, Content } = Layout;
    const { current, surveys, fields, file, loadingUpload, selectedFields, notReady, headerChecked, optinChecked } = this.state;
    const { user } = this.props.user;

    const self = this;

    const steps = [
      { title: 'Pesquisa' },
      { title: 'Upload' },
      { title: 'Campos' },
      { title: 'Fim' }
    ];

    const filterField = defaultFields.filter(i => !selectedFields.includes(i.label));

    const props = {
      name: 'file',
      accept: '.csv, .xlsx, .xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      beforeUpload(file) {
        const type = file.name.split('.').pop()
        const size = file.size
        if (size >= 10000000) {
          message.error('O arquivo ultrapassao o limite de tamanho de 10MB');
          return false
        }
        if (!['csv', 'xlsx', 'xls'].includes(type.toLowerCase())) {
          message.error('Formato de arquivo não é permitido');
          return false
        }
        self.setState({file: file});
        return false
      }
    };

    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed} width={200}>
          <Menu
            onClick={this.handleClick}
            style={{ height: '100%' }}
            defaultSelectedKeys={['enviar-base']}
          >
            <Menu.Item key="bases"  icon={window.innerWidth < 769 && <ProfileOutlined />}>Histórico</Menu.Item>
            <Menu.Item key="enviar-base"  icon={window.innerWidth < 769 && <UploadOutlined />}>Enviar Base</Menu.Item>
          </Menu>
        </Sider>
        <Content className="site-layout" style={{ padding: 0, margin: 0, minHeight: 440, background: 'white' }}>
          <div className="site-layout-background" style={{ padding: 16, paddingTop: 0 }}>
            <Title level={4}>
            {window.innerWidth < 769 && React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}
            Envio de Base</Title>
            <Steps current={current}>
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content" style={{ margin: 24, minHeight: 200 }}>
              {current === 0
              ? <Form>
                <Form.Item
                  label="Pesquisa"
                  style={{ display: 'inline-block', width: '100%', marginBottom: 6 }} >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    loading={notReady}
                    disabled={!this.state.isNotSurvey ? notReady : this.state.isNotSurvey }
                    placeholder="Selecione uma pesquisa"
                    onSearch={this.onSearch}
                    onSelect={e => this.handleSelect('surveyid',e)}
                  >
                    {surveys.filter( survey => survey.status !== 'Inativo').map(item => <Option value={item.id}>{['admin','customersuccess'].includes(user.role) && ` ${item.client.toUpperCase()} - `}{item.name}</Option>)}
                  </Select>
                  <Checkbox
                    style={{ marginTop: 8 }}
                    onChange={this.handleAnotherBase}
                  >
                    Não encontrei o ponto de contato/pesquisa
                  </Checkbox>
                </Form.Item>
                <Form.Item style={{ float: 'right', marginTop: 24 }}>
                  <Button type="primary" htmlType="submit" onClick={() => {
                    this.state.hasOwnProperty('surveyid') ? this.next() : message.error('Você precisa selecionar um ponto de contato/pesquisa.')
                  }}>
                    Próximo
                  </Button>
                </Form.Item>
              </Form>
              : current === 1
              ? <Form>
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Clique aqui ou arraste o arquivo para fazer upload</p>
                    <p className="ant-upload-hint">Upload de um único arquivo, no formato XLSX, XLS ou CSV com no máximo 10MB.</p>
                </Dragger>
                <Title level={4} style={{marginTop: 16}}>Opções</Title>
                <Form.Item style={{marginBottom: 4}}>
                  <Checkbox
                    checked={headerChecked}
                    onChange={() => this.setState({headerChecked: !headerChecked})}
                  >
                    O arquivo inclui uma linha de cabeçalho
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={optinChecked}
                    onChange={() => this.setState({optinChecked: !optinChecked})}
                  >
                    Tenho permissão para enviar mensagens para os contatos desta base<br/><small>Confirmo que tenho permissão explícita para enviar mensagens a todos os contatos enviados à Inovyo, de acordo com a legislação local, estadual, federal e internacional.</small>
                  </Checkbox>
                </Form.Item>
                <Form.Item style={{ float: 'right', marginTop: 24 }}>
                  <Button type="primary" htmlType="submit"
                    onClick={this.handleUpload}
                    disabled={file.length === 0 ? optinChecked : !optinChecked}
                    loading={loadingUpload}
                  >
                    {loadingUpload ? 'Fazendo upload' : 'Próximo'}
                  </Button>
                </Form.Item>
              </Form>
              : current === 2
              ? <Form>
                <Row>
                  <Col span={24}>
                    <p>Agora, combine as colunas do arquivo carregado com o campo de contato da pesquisa.</p>
                  </Col>
                </Row>
                <Divider />
                <Row style={{marginBottom: 6}}>
                  <Col span={12}>
                    <p style={{fontWeight: 'bold'}}>Cabeçalho do arquivo</p>
                  </Col>
                  <Col span={12}>
                    <p style={{fontWeight: 'bold'}}>Campos da pesquisa</p>
                  </Col>
                </Row>
                {fields.map((item, index) => (
                  <Row style={{marginBottom: 6}}>
                    <Col span={12}>
                      <p>{headerChecked ? item : `Coluna ${String.fromCharCode(97 + index).toUpperCase()}`}</p>
                    </Col>
                    <Col span={12}>
                      <Select
                        allowClear
                        labelInValue
                        placeholder="Selecione um campo"
                        style={{width: '100%'}}
                        onChange={e => this.handleSelectFields(item, e, index)}
                      >
                        <Option value="0">Ignorar campo</Option>
                        {Object.keys(filterField).map(id =>
                          <Option key={filterField[id].value} title={filterField[id].label} value={filterField[id].value}>{filterField[id].label}</Option>)
                        }
                      </Select>
                    </Col>
                  </Row>
                ))}
                <Form.Item style={{ float: 'right', marginTop: 24 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={this.handleSubmit}
                    loading={loadingUpload}
                  >
                    {loadingUpload ? 'Salvando mapa de campos' : 'Concluir'}
                  </Button>
                </Form.Item>
              </Form>
              : current === 3 &&
              <div>
                <Result
                  status="success"
                  title="Base recebida!"
                  extra={[
                    <Button type="primary" key="again" onClick={() => this.setState({current: 0})}>
                      Enviar nova base
                    </Button>,
                    <Button type="secondary" key="bases" onClick={() => this.handleClick({key: 'bases'})}>
                      Ir para Histórico
                    </Button>
                  ]}
                />
              </div>
              }
            </div>
          </div>
        </Content>
      </Layout>
    )
  }
}

export default connect(state => state)(withRouter(SendBase));
