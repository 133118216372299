import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Checkbox, Typography, Form, Layout, Menu, message, Input, Select, Button } from 'antd';
import { ApiOutlined, IdcardOutlined, UserOutlined, UserAddOutlined, TeamOutlined , MailOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { addUser } from '../actions';

class NewUser extends Component {
  state = {
    user: '',
    company: '',
    email: '',
    name: '',
    role: '',
    oauth_only: false,
    collapsed: window.innerWidth > 768 ? false : true
  }

  componentDidMount() {
    const { user } = this.props.user
    if (!['admin','customersuccess'].includes(user.role)) {
      this.props.history.push('/usuarios');
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleChange = e => {
    let data = {}
    const key = e.target.name;
    const value = e.target.value.trim();
    data[key] = value;
    this.setState(data);
  };

  handleSelect = e => {
    this.setState({role: e});
  };

  handleCheckbox = () => {
    this.setState({oauth_only: !this.state.oauth_only});
  };

  handleSubmit = e => {
    const { dispatch } = this.props;
    const credentials = JSON.stringify(this.state)
    message.loading({ content: 'Criando usuário', key: 'newuser', duration: 0 });
    axios({
      method: 'post',
      url: '/api/novo-usuario',
      data: credentials
    })
    .then(result => {
      dispatch(addUser())
      message.success({ content: 'Usuário criado com sucesso', key: 'newuser' })
      this.props.history.push('/usuarios')
    })
    .catch( err => {
      if (err.response.data.hasOwnProperty('detail')) {
        message.error({ content: err.response.data.detail, key: 'newuser' })
      } else {
        message.error({ content: 'Ocorreu um erro, tente novamente mais tarde', key: 'newuser' })
      }
    })
  };

  handleClick = e => {
    this.props.history.push(e.key)
  };
  
  render() {
    const { Title } = Typography;
    const { Option } = Select;
    const { Content, Sider } = Layout;

    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed} width={200}>
          <Menu
            onClick={this.handleClick}
            style={{ height: '100%' }}
            defaultSelectedKeys={['novo-usuario']}
          >
          <Menu.Item key="usuarios" icon={window.innerWidth < 769 && <TeamOutlined />}>Todos os Usuários</Menu.Item>
          <Menu.Item key="novo-usuario" icon={window.innerWidth < 769 && <UserAddOutlined />}>Criar usuário</Menu.Item>
          </Menu>
        </Sider>
        <Content className="site-layout" style={{ padding: 0, margin: 0, minHeight: 440, background: 'white' }}>
          <div className="site-layout-background" style={{ padding: 16, paddingTop: 0 }}>
            <Title level={4}>
            {window.innerWidth < 769 && React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}
            Novo usuário</Title>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
            >
              <Form.Item
              label="Nome da conta"
              name="user"
              rules={[{ required: true, message: 'Por favor, insira o nome da conta' }]}
              >
                <Input onChange={this.handleChange} prefix={<ApiOutlined />} name="user" id="user" />
              </Form.Item>
              <Form.Item
              label="Empresa"
              name="company"
              rules={[{ required: true, message: 'Por favor, insira o nome da empresa' }]}
              >
                <Input onChange={this.handleChange} prefix={<IdcardOutlined />} name="company" id="company" />
              </Form.Item>
              <Form.Item
              label="Contato"
              name="name"
              rules={[{ required: true, message: 'Por favor, insira o nome do contato' }]}
              >
                <Input onChange={this.handleChange} prefix={<UserOutlined />} name="name" id="name" />
              </Form.Item>
              <Form.Item
              label="E-mail"
              name="email"
              rules={[{ required: true, message: 'Por favor, insira um e-mail' }]}
              >
                <Input onChange={this.handleChange} prefix={<MailOutlined />} name="email" id="email" />
              </Form.Item>
              <Form.Item
              label="Permissão"
              name="role"
              rules={[{ required: true, message: 'Por favor, selecione uma permissão' }]}
              >
                <Select
                  id="role"
                  name="role"
                  placeholder="Selecione uma permissão"
                  onSelect={this.handleSelect}
                >
                  <Option value="admin">Administrador</Option>
                  <Option value="customersuccess">Customer Success</Option>
                  <Option value="sale">Comercial</Option>
                  <Option value="client">Cliente</Option>
                  <Option value="demo">Demo</Option>
                  <Option value="ftp">FTP</Option>
                </Select>
              </Form.Item>
              <Form.Item name="oauth_only">
                <Checkbox style={{ marginTop: 8 }} name="oauth_only" onClick={this.handleCheckbox}>
                  Gerenciado pelo cliente
                </Checkbox>
              </Form.Item>
              <Form.Item style={{ float: 'right' }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Criar usuário
              </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Layout>
    )
  }
}

export default connect(state => state)(withRouter(NewUser));