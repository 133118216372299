import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import api from '../service/api'
import { Button, Card, Form, Input, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { userLogin } from '../actions';

class Login extends Component {
  state = {
    email: '',
    password: ''
  }

  componentDidMount() {
    if (this.props.user.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  handleChange = e => {
    let data = {}
    const key = e.target.name;
    const value = e.target.value;
    data[key] = value;
    this.setState(data);
  };

  handleSubmit = () => {
    const { dispatch } = this.props
    const credentials = JSON.stringify(this.state)
    message.loading({ content: 'Realizando login', key: 'login', duration: 0 });
    api.post('/login', credentials)
    .then(result => {
      dispatch(
        userLogin(result.data)
      )
      localStorage.setItem('jwtToken', result.data.jwtToken)
      message.success({ content: 'Agora você está logado', key: 'login' })
      this.props.history.push('/')
    })
    .catch( err => {
      if (err.response.data.hasOwnProperty('detail')) {
        message.error({ content: err.response.data.detail, key: 'login' })
      } else {
        message.error({ content: 'Ocorreu um erro, tente novamente mais tarde', key: 'login' })
      }
    })
  };

  render() {
    const { email, password } = this.state;
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };

    return(
      <div className="space-align-container">
        <Card className="space-align-block">
          <Form
            {...layout}
            name="login"
            initialValues={{ remember: true }}
          >
            <Form.Item>
              <div className="logo" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Por favor, insira seu e-mail!' }]}
            >
              <Input
                id="email"
                name="email"
                prefix={<UserOutlined />}
                placeholder="E-mail"
                value={email}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}
            >
              <Input.Password
                id="password"
                name="password"
                prefix={<LockOutlined />}
                placeholder="Senha"
                value={password}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                ENTRAR
              </Button>
            </Form.Item>
            <Form.Item>
              <Link to="/redefinir-senha">Esqueceu a senha?</Link>
            </Form.Item>
            <Form.Item>
              <div className="google-login-inovyo">
                <a href='/api/google_auth'>Entrar com o Google</a>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    )
  }
}

export default connect(state => state)(withRouter(Login));