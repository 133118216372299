import axios from "axios";

const hostName = window.location.hostname;

const baseURL =
  hostName === "localhost"
    ? "localhost:8000/api"
    : "/api";

const settings = {
  baseURL,
  responseType: "json",
};

const api = axios.create(settings);

export default api;
