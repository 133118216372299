import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Layout, Menu, Typography, message, Row, Col} from 'antd';
import { SettingOutlined, ApiOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import api from '../service/api';

class Integration extends Component {
  state = {
    collapsed: window.innerWidth > 768 ? false : true,
    api_token: '',
    api_secret: ''
  }

  componentDidMount() {
    const token = localStorage.getItem('jwtToken')
    api.get('/conta/acesso-api', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(secrets => {
      let {api_token, api_secret} = secrets.data
      this.setState({api_token, api_secret});
    })
    .catch(err => {
      message.error({ content: 'Ocorreu um erro, tente novamente mais tarde', key: 'integrationDataUser' })
    })
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleClick = e => {
    this.props.history.push(e.key)
  };

  render() {
    const { user } = this.props.user;
    const { api_secret, api_token } = this.state;
    const { Title, Text, Link } = Typography;
    const { Content, Sider } = Layout;

    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed} width={200}>
          <Menu
            onClick={this.handleClick}
            style={{ height: '100%' }}
            defaultSelectedKeys={['integracao']}
          >
            <Menu.Item key="conta" icon={window.innerWidth < 769 && <SettingOutlined />}>Conta</Menu.Item>
            <Menu.Item key="integracao" icon={window.innerWidth < 769 && <ApiOutlined />}>Integração</Menu.Item>
          </Menu>
        </Sider>
        <Content className="site-layout" style={{ padding: 0, margin: 0, minHeight: 440, background: 'white' }}>
          <div className="site-layout-background" style={{ padding: 16, paddingTop: 0 }}>
          <Title level={4}>
            {window.innerWidth < 769 && React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}
            Integração</Title>
            <Text>Utilize os dados abaixo para realizar integração com nossa solução. <Link underline href="https://api.inovyo.com/new/docs" target="_blank">Clique aqui</Link> para acessar a documentação da API.</Text>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              style={{marginTop: 24 }}
            >
              <Input.Group>
                <Row gutter={24}>
                  <Col xs={24} sm={24}>
                    <label htmlFor="">Cliente</label>
                    <p style={{color: '#a3a3a3'}}>{user.user}</p>
                  </Col>
                  <Col xs={24} sm={24}>
                    <label htmlFor="">Token</label>
                    <p style={{color: '#a3a3a3'}}>{api_token}</p>
                  </Col>
                  <Col xs={24} sm={24}>
                    <label htmlFor="">Secret Token</label>
                    <p style={{color: '#a3a3a3'}}>{api_secret}</p>
                  </Col>
                </Row>
              </Input.Group>
            </Form>
          </div>
        </Content>
      </Layout>
    )
  }
}

export default connect(state => state)(withRouter(Integration));