import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { Col, Button, Form, Input, Layout, Menu, message, Modal, Row, Tooltip, Typography } from 'antd';
import { SettingOutlined, ApiOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import api from '../service/api';

class Account extends Component {

  state = {
    email: '',
    show: false,
    collapsed: window.innerWidth > 768 ? false : true,
    company: ''
  }

  componentDidMount() {
    const token = localStorage.getItem('jwtToken')
    api.get('/conta/detalhes', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(user => {
      let {email, company, oauth_only} = user.data
      this.setState({email, company, oauth_only}, () => {
        console.log(this.state)
      });
    })
    .catch(err => {
      message.error({ content: 'Ocorreu um erro, tente novamente mais tarde', key: 'detailsAccount' })
    })
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleChange = e => {
    let data = {}
    const key = e.target.name;
    const value = e.target.value;
    data[key] = value;
    this.setState(data);
  };

  handleSubmit = e => {
    message.loading({ content: 'Alterando senha', key: 'changepass', duration: 0 });
    const credentials = JSON.stringify({
      email: this.state.email,
      password: this.state.password,
      newpassword: this.state.newpassword,
    })

    axios({
      method: 'post',
      url: '/api/alterar-senha',
      data: credentials
    })
      .then(result => {
        message.success({ content: 'Senha alterada com sucesso', key: 'changepass' })
        this.props.history.push('/login')
      })
      .catch(err => {
        if (err.response.data.hasOwnProperty('detail')) {
          message.error({ content: err.response.data.detail, key: 'changepass' })
        } else {
          message.error({ content: 'Ocorreu um erro, tente novamente mais tarde', key: 'changepass' })
        }
      })
  };

  handleClick = e => {
    this.props.history.push(e.key)
  };

  render() {
    const { show, company, email } = this.state;
    //const { user } = this.props.user;
    const { Title } = Typography;
    const { Content, Sider } = Layout;

    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed} width={200}>
          <Menu
            onClick={this.handleClick}
            style={{ height: '100%' }}
            defaultSelectedKeys={['conta']}
          >
            <Menu.Item key="conta" icon={window.innerWidth < 769 && <SettingOutlined />}>Conta</Menu.Item>
            <Menu.Item key="integracao" icon={window.innerWidth < 769 && <ApiOutlined />}>Integração</Menu.Item>
          </Menu>
        </Sider>
        <Content className="site-layout" style={{ padding: 0, margin: 0, minHeight: 440, background: 'white' }}>
          <div className="site-layout-background" style={{ padding: 16, paddingTop: 0 }}>
            <Row>
              <Col span={24}>
                <Title level={4}>
                  {window.innerWidth < 769 && React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: this.toggle,
                  })}
                Informações da Conta</Title>
              </Col>
            </Row>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
            >
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <label htmlFor="">Empresa</label>
                  <p style={{color: '#a3a3a3'}}>{company}</p>
                </Col>
                <Col xs={24} sm={12}>
                  <label htmlFor="">Email</label>
                  <p style={{color: '#a3a3a3'}}>{email}</p>
                </Col>
              </Row>
              {this.state.oauth_only === false &&
              <div>
                <Row>
                  <Col span={24}>
                    <Title level={4}>Alterar Senha</Title>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Tooltip
                      placement="bottom"
                      title="A senha deve conter no mínimo 8 caracteres, ao menos uma letra maíuscula, uma letra minúscula e um número."
                    >
                      <Form.Item
                        label="Nova senha"
                        name="newpassword"
                        rules={[
                          { required: true, message: 'Por favor, insira uma nova senha!' },
                          () => ({
                            validator(rule, value) {
                              if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(value)) {
                                return Promise.resolve();
                              }
                              return Promise.reject('A senha não atende os requisitos de segurança');
                            }
                          })
                        ]}
                        style={{ display: 'inline-block', width: '100%' }}
                      >
                        <Input.Password
                          name="newpassword"
                          id="newpassword"
                          onChange={this.handleChange} />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Confirmar senha"
                      name="confirm-new-password"
                      dependencies={['newpassword']}
                      rules={[
                        { required: true, message: 'Por favor, repita a senha digitada anteriormente!' },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue('newpassword') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject('As senhas digitadas não coincidem');
                          },
                        })
                      ]}
                      style={{ display: 'inline-block', width: '100%' }}
                    >
                      <Input.Password
                        name="confirm-new-password"
                        id="confirm-new-password"
                        onChange={this.handleChange} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ float: 'right' }}>
                  <Form.Item style={{ float: 'right' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => this.setState({ show: !show })}
                    >
                      Alterar senha
                    </Button>
                  </Form.Item>
                </Row>
              </div>
             }
            </Form>
          </div>
        </Content>
        <Modal
          title="Confirmar alteração de senha"
          visible={show}
          style={{ top: 20 }}
          footer={[
            <Button key="back" onClick={() => this.setState({ show: !show })}>
              Cancelar
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleSubmit}>
              Confirmar
            </Button>
          ]}
        >
          <Form.Item
            label="Por favor, insira a sua senha atual"
            name="password"
            rules={[{ required: true, message: 'Por favor, insira a senha atual!' }]}
            style={{ margin: '0 auto', display: 'inline-block', width: '100%' }}
          >
            <Input.Password
              name="password"
              id="password"
              onChange={this.handleChange} />
          </Form.Item>
        </Modal>
      </Layout>
    )
  }
}

export default connect(state => state)(withRouter(Account));