import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Form, Input, message, Tooltip } from 'antd';
import { LockOutlined, ArrowLeftOutlined } from '@ant-design/icons';

class NewPass extends Component {
  state = {
    email: '',
    password: '',
    code: ''
  }

  componentDidMount() {
    if( !this.props.history.location.state ) {
      message.error({ content: "Você não tem permissão para acessar essa tela e será redirecionado..", key: 'changepass' })
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
      return
    }

    const {email, code} = this.props.history.location.state
    this.setState({email, code})
  }

  handleChange = e => {
    let data = {}
    const key = e.target.name;
    const value = e.target.value;
    data[key] = value;
    this.setState(data);
  };

  handleSubmit = () => {
    const hide = message.loading({ content: 'Alterando senha', key: 'changepass', duration: 0});
    axios({
      method: 'post',
      url: '/api/nova-senha',
      data: JSON.stringify(this.state)
    })
    .then(() => {
      hide()
      message.success({ content: 'Senha alterada com sucesso, realize seu login', key: 'changepass' })
      this.props.history.push('/login')
    })
    .catch( err => {
      hide()
      if (err.response.data.hasOwnProperty('detail')) {
      message.error({ content: err.response.data.detail, key: 'changepass' })
      } else {
      message.error({ content: 'Ocorreu um erro, tente novamente mais tarde', key: 'changepass' })
      }
    })
  };

    render() {
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return(
      <div className="space-align-container">
        <Card className="space-align-block">
          <Form
            {...layout}
            name="login"
            initialValues={{ remember: true }}
          >
            <Form.Item>
              <div className="logo" />
            </Form.Item>
            <Tooltip
              placement="rightBottom"
              title="A senha deve conter no mínimo 8 caracteres, ao menos uma letra maíuscula, uma letra minúscula e um número."
            >
              <Form.Item
                label="Por favor, insira a nova senha"
                name="password"
                rules={[
                  { required: true, message: 'Por favor, insira uma senha!' },
                  () => ({
                  validator(rule, value) {
                    if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(value)) {
                    return Promise.resolve();
                    }
                    return Promise.reject('A senha não atende os requisitos de segurança');
                  }
                  })
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Senha"
                  onChange={this.handleChange}
                />
              </Form.Item>
            </Tooltip>
            <Form.Item>
              <Button type="primary" block htmlType="submit" onClick={this.handleSubmit}>
                ALTERAR SENHA
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="link" onClick={() => this.props.history.goBack()}><ArrowLeftOutlined /> Voltar</Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    )
  }
}

export default withRouter(NewPass);