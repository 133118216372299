//import * as serviceWorker from "./serviceWorker";
import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import ptBR from "antd/es/locale/pt_BR";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import { BrowserRouter as Router } from "react-router-dom";

//components
import App from "./App";
import setAuthToken from "./utils/setAuthToken";
import { userLogin, userLogout } from "./actions";
import store from "./store";

//styles
import "./index.css";

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(userLogin(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(userLogout());
    window.location.href = "./login";
  }
}

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={ptBR}>
      <Router>
        <App />
      </Router>
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
