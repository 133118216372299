import setAuthToken from "../utils/setAuthToken";
import _ from "lodash";

export const FETCH_USER = "FETCH_USER";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const DISABLE_NOTIFICATION = "DISABLE_NOTIFICATION";
export const FETCH_APPS = "FETCH_APPS";
export const FETCH_ALLUSER = "FETCH_ALLUSER";
export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";
export const FETCH_SURVEYS = "FETCH_SURVEYS";
export const ADD_SURVEY = "ADD_SURVEY";
export const FETCH_FILES = "FETCH_FILES";
export const ADD_FILE = "ADD_FILE";
export const DELETE_FILE = "DELETE_FILE";
export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const ACTUAL_PATH = "ACTUAL_PATH";

export const userLogin = (userData) => {
  return {
    type: FETCH_USER,
    payload: userData,
  };
};

export const userLogout = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(userLogin({}));
};

export const getNotifications = (result) => {
  return {
    type: FETCH_NOTIFICATIONS,
    payload: result,
  };
};

export const disableNotification = (id) => {
  return {
    type: DISABLE_NOTIFICATION,
    payload: id,
  };
};

export const getApps = (result) => {
  return {
    type: FETCH_APPS,
    payload: result,
  };
};

export const getAllUsers = (result) => {
  return {
    type: FETCH_ALLUSER,
    payload: result,
  };
};

export const addUser = () => {
  return {
    type: ADD_USER,
  };
};

export const delUser = () => {
  return {
    type: DELETE_USER,
  };
};

export const getAllFiles = (result) => {
  return {
    type: FETCH_FILES,
    payload: result,
  };
};

export const addFile = () => {
  return {
    type: ADD_FILE,
  };
};

export const delFile = () => {
  return {
    type: DELETE_FILE,
  };
};

export const getSurveys = (result) => {
  return {
    type: FETCH_SURVEYS,
    payload: result,
  };
};

export const addSurvey = () => {
  return {
    type: ADD_SURVEY,
  };
};

export const getClients = (result) => {
  return {
    type: FETCH_CLIENTS,
    payload: result,
  };
};
export const getPath = (result) => {
  const array = [];
  const actualPath = _.concat(
    array,
    result === "/enviar-base"
      ? "bases"
      : result === "/adicionar-pesquisa"
      ? "pesquisas"
      : result === "/novo-usuario"
      ? "usuarios"
      : result === "/integracao"
      ? "conta"
      : result === "/"
      ? result
      : result.replace("/", "")
  );

  return {
    type: ACTUAL_PATH,
    payload: actualPath,
  };
};
