import React, { Component } from 'react';
import { Card, Typography } from 'antd';
import { Divider } from 'antd';

export default class NoMatch extends Component {
  render() {
    const { Title, Text } = Typography;
    return(
    <div className="space-align-container">
      <Card className="space-align-block">
        <div className="logo" />
        <Divider />
        <Title level={2} style={{marginTop: 36}}>Oops!</Title>
        <Text>A página que você está tentando acessar não existe. Por favor, verifique o endereço digitado em seu navegador e tente novamente.</Text>
      </Card>
    </div>
    )
  }
}