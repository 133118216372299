import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { List, Button, Col, Divider, Form, Input, Layout, Menu, message, Row, Select, Switch, Tooltip, Typography } from 'antd';
import { ArrowRightOutlined, OrderedListOutlined, PlusCircleOutlined, MinusOutlined, PlusOutlined, PushpinOutlined, MailOutlined, NumberOutlined, BellOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { addSurvey, getClients } from '../actions';

class AddSurvey extends Component {
  state = {
    emailAlert: false,
    client: '',
    division: '',
    status: '',
    weekDays: [
      {'label': 'Segunda-feira', 'value': 1},
      {'label': 'Terça-feira', 'value': 2},
      {'label': 'Quarta-feira', 'value': 3},
      {'label': 'Quinta-feira', 'value': 4},
      {'label': 'Sexta-feira', 'value': 5},
      {'label': 'Sábado', 'value': 6},
      {'label': 'Domingo', 'value': 7}
    ],
    tempInput: '',
    tempSelect: '',
    surveyfields: {},
    notification: {},
    schedule: {
      0:{ activationdays: {}, wait: '' },
      1:{ activationdays: {}, wait: '' },
      2:{ activationdays: {}, wait: '' }
    },
    scheduleDom: {'0': [{title:'Primeira ativação', key: 0, active: true}],'1': [{title:'Primeiro lembrete', key: 0, active: true}], '2': [{title:'Segundo lembrete', key: 0, active: true}]},
    clients: [],
    collapsed: window.innerWidth > 768 ? false : true
  }

  componentDidMount() {
    const { user } = this.props.user;
    const { dispatch } = this.props;
    if (!['admin','customersuccess'].includes(user.role)) {
      this.props.history.push('/pesquisas');
    }
    axios.get('/api/clientes')
    .then(res => {
      const clients = res.data.data.sort( (a, b) => {return (a.value < b.value) ? -1 : (a.value > b.value) ? 1 : 0})
      dispatch(getClients(clients))
      this.setState({clients: clients})
    })
    .catch(err => console.log(err))
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  tempFieldsInput = e => {
    this.setState({tempInput: e.target.value});
  }

  tempFieldsSelect = value => {
    this.setState({tempSelect: value});
  }

  addCf = () => {
    const { surveyfields, tempInput, tempSelect } = this.state;
    surveyfields[tempInput] = tempSelect
    let empty = ''
    this.setState({surveyfields: surveyfields, tempInput: empty, tempSelect: empty});
    console.log(surveyfields)
  }

  handleChange = e => {
    let data = {}
    const key = e.target.name;
    const value = e.target.value;
    data[key] = value;
    this.setState(data);
  };

  alertCheck = () => {
    this.setState({emailAlert: !this.state.emailAlert})
  }

  handleSelect = (key, value) => {
    const el = {};
    el[key] = value;
    this.setState(el);
  };

  handleNotificationChange = e => {
    const { notification } = this.state;
    const key = e.target.name;
    const value = e.target.value;
    notification[key] = value;
    this.setState({notification: notification});
  };

  handleNotificationSelect = (key, value) => {
    const { notification } = this.state;
    notification[key] = value;
    this.setState({notification: notification});
  };

  handleScheduleSelect = (type, key, value, domKey) => {
    const { schedule, scheduleDom } = this.state;
    if (type === 'days') {
      if (!schedule[value].activationdays.hasOwnProperty(key)) {
        schedule[value].activationdays[key] = []
        if (scheduleDom[value][domKey].hasOwnProperty('activationdays')) {
          scheduleDom[value][domKey].activationdays.push(key)
          this.setState({scheduleDom: scheduleDom})
        } else {
          scheduleDom[value][domKey]['activationdays'] = []
          scheduleDom[value][domKey].activationdays.push(key)
          this.setState({scheduleDom: scheduleDom})
        }
      } else {
        if (scheduleDom[value][domKey].hasOwnProperty('activationdays')) {
          scheduleDom[value][domKey].activationdays.push(key)
          this.setState({scheduleDom: scheduleDom})
        } else {
          scheduleDom[value][domKey]['activationdays'] = []
          scheduleDom[value][domKey].activationdays.push(key)
          this.setState({scheduleDom: scheduleDom})
        }
      }
    }
    if (type === 'wait') {
      schedule[value].wait = key
    }
    if (['hour','minute'].includes(type)) {
      Object.keys(schedule[value].activationdays).forEach(day => {
        const arr = scheduleDom[value][domKey].activationdays
        if (arr.indexOf(parseInt(day)) !== -1 ) {
          const el = {};
          el[type] = key
          schedule[value].activationdays[day].push(el)
        }
      })
    }
    this.setState({schedule: schedule});
  };

  handleSubmit = e => {
    const { dispatch } = this.props;
    const data = _.clone(this.state)
    delete data['clients'];
    delete data['scheduleDom'];
    delete data['weekDays'];
    delete data['tempInput'];
    delete data['tempSelect'];
    data['emailAlert'] === false && delete data['notification'];
    delete data['emailAlert'];
    const survey = JSON.stringify(data)

    message.loading({ content: 'Adicionando pesquisa', key: 'addsurvey', duration: 0 });
    axios({
      method: 'post',
      url: '/api/adicionar-pesquisa',
      data: survey
    })
    .then(result => {
      message.success({ content: 'Pesquisa adicionada com sucesso', key: 'addsurvey' })
      dispatch(addSurvey())
      this.setState({
        emailAlert: false,
        client: '',
        division: '',
        status: '',
        weekDays: [
          {'label': 'Segunda-feira', 'value': 1},
          {'label': 'Terça-feira', 'value': 2},
          {'label': 'Quarta-feira', 'value': 3},
          {'label': 'Quinta-feira', 'value': 4},
          {'label': 'Sexta-feira', 'value': 5},
          {'label': 'Sábado', 'value': 6},
          {'label': 'Domingo', 'value': 7}
        ],
        tempInput: '',
        tempSelect: '',
        surveyfields: {},
        notification: {},
        schedule: {
          0:{ activationdays: {}, wait: '' },
          1:{ activationdays: {}, wait: '' },
          2:{ activationdays: {}, wait: '' }
        },
        scheduleDom: {'0': [{title:'Primeira ativação', key: 0, active: true}],'1': [{title:'Primeiro lembrete', key: 0, active: true}], '2': [{title:'Segundo lembrete', key: 0, active: true}]},
        clients: [],
        collapsed: window.innerWidth > 768 ? false : true
      })
      this.props.history.push('/pesquisas')
    })
    .catch( err => message.error({ content: 'Ocorreu um erro, tente novamente mais tarde', key: 'addsurvey' }))
  };

  handleClick = e => {
    this.props.history.push(e.key)
  };

  duplicateLine = index => {
    const { scheduleDom } = this.state;
    const maxKey = Math.max(...scheduleDom[index].map(i => i.key))
    scheduleDom[index].push({title: '', key: maxKey+1, active: false})
    this.setState({scheduleDom: scheduleDom })
  }

  removeLine = (index, key) => {
    const { scheduleDom } = this.state;
    const copySchedule = scheduleDom;
    copySchedule[index] = _.pullAllBy(scheduleDom[index], [{ 'key': key }], 'key');
    this.setState({scheduleDom: copySchedule})
  }

  render() {
    const { Title, Text } = Typography;
    const { Option } = Select;
    const { Content, Sider } = Layout;
    const { clients, scheduleDom, emailAlert, weekDays, surveyfields } = this.state;

    return (
      <Layout>
      <Sider trigger={null} collapsible collapsed={this.state.collapsed} width={200}>
        <Menu
        onClick={this.handleClick}
        style={{ height: '100%' }}
        defaultSelectedKeys={['adicionar-pesquisa']}
        >
        <Menu.Item key="pesquisas" icon={window.innerWidth < 769 && <OrderedListOutlined />}>Todos as Pesquisas</Menu.Item>
        <Menu.Item key="adicionar-pesquisa" icon={window.innerWidth < 769 && <PlusCircleOutlined />}>Adicionar pesquisa</Menu.Item>
        </Menu>
      </Sider>
      <Content className="site-layout" style={{ padding: 0, margin: 0, minHeight: 440, background: 'white' }}>
        <div className="site-layout-background" style={{ padding: 16, paddingTop: 0 }}>
        <Title level={4}>
          {window.innerWidth < 769 && React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: this.toggle,
          })}
          Adicionar pesquisa</Title>
        <Form
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
        >
          <Form.Item
          label="Ponto de contato"
          name="survey"
          rules={[{ required: true, message: 'Por favor, insira um nome' }]}
          >
            <Input onChange={this.handleChange} prefix={<PushpinOutlined />} name="survey" id="survey" />
          </Form.Item>
          <Row gutter={[16]}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
              label="Cliente"
              name="client"
              rules={[{ required: true, message: 'Por favor, selecione um cliente' }]}
              style={{ display: 'inline-block', width: '100%' }}
              >
                <Select
                  showSearch
                  id="Cliente"
                  name="client"
                  placeholder="Selecione um cliente"
                  onSelect={e => this.handleSelect('client',e)}
                >
                  {clients.length > 0 ? clients.map(client => <Option value={client.value}>{client.label}</Option>) : 'Carregando'}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="ID pesquisa"
                name="sgid"
                rules={[{ required: true, message: 'Por favor, insira o ID da pesquisa no Survey Gizmo' }]}
                style={{ display: 'inline-block', width: '100%' }}
              >
                <Input onChange={this.handleChange} prefix={<NumberOutlined />} name="sgid" id="sgid" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="ID campanha modelo"
                name="sgaidmodel"
                style={{ display: 'inline-block', width: '100%' }}
              >
                <Input onChange={this.handleChange} prefix={<MailOutlined />} name="sgaidmodel" id="sgaidmodel" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                label="Categoria"
                name="division"
                rules={[{ required: true, message: 'Por favor, selecione uma categoria' }]}
                style={{ display: 'inline-block', width: '100%' }}
              >
                <Select
                  id="division"
                  name="division"
                  placeholder="Selecione uma categoria"
                  onSelect={e => this.handleSelect('division',e)}
                >
                  <Option value="AH">Ad Hoc</Option>
                  <Option value="CX">Customer Experience</Option>
                  <Option value="KN">Knowtics</Option>
                  <Option value="RT">Real Time</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true, message: 'Por favor, selecione um status' }]}
                style={{ display: 'inline-block', width: '100%' }}
              >
                <Select
                  id="status"
                  name="status"
                  placeholder="Selecione um status"
                  onSelect={e => this.handleSelect('status',e)}
                >
                  <Option value="A">Ativo</Option>
                  <Option value="I">Inativo</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} style={{marginTop: 34}}>
              <Tooltip placement="bottomRight" title="Habilitar alerta por e-mail para que o usuário envie base manualmente pela plataforma">
                <Switch checked={emailAlert} onChange={this.alertCheck} /> Alerta de envio de base
              </Tooltip>
            </Col>
          </Row>
          <Title level={4} style={{ marginTop: 16 }}>Mapa de Campos Customizados</Title>
          <Divider />
          <Row gutter={[8]}>
            <Col xs={24} sm={10} md={10} style={{marginBottom: 8}}>
              <Input
                placeholder="Nome do Campo"
                onChange={this.tempFieldsInput}
                name="cf"
                id="cf"
                allowClear
              />
            </Col>
            <Col xs={24} sm={10} md={10} style={{marginBottom: 8}}>
              <Select
                id="period"
                name="period"
                placeholder="Selecione um Custom Field"
                style={{width: '100%'}}
                onSelect={(e) => this.tempFieldsSelect(e)}
                allowClear
              >
                {Array.from(Array(10).keys()).map(item => (<Option value={`scustomfield${item + 1}`}>Custom Field {item + 1}</Option>))}
              </Select>
            </Col>
            <Col xs={24} sm={4} md={4} style={{marginTop: 3}}>
              <Button
                size="small"
                icon={<PlusOutlined />}
                style={{marginRight: 6}}
                onClick={() => this.addCf()} >Adicionar</Button>
            </Col>
          </Row>
          {Object.entries(surveyfields).length > 0 &&
            <Row>
              <Col xs={24}>
              <List bordered>
                {Object.entries(surveyfields).map(i => (<List.Item>{i[0]} <ArrowRightOutlined /> {i[1]}</List.Item>))}
              </List>
              </Col>
            </Row>
          }
          {emailAlert === true && <div>
            <Title level={4} style={{ marginTop: 16 }}>Upload manual</Title>
            <Divider />
              <div>
                <Row gutter={[16]}>
                  <Col span={24} style={{marginBottom: 8}}>
                    <Text strong>Configurar Alerta</Text>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Select
                      id="period"
                      name="period"
                      placeholder="Selecione a periodicidade"
                      style={{width: '100%'}}
                      onSelect={(e) => this.handleNotificationSelect('period',e)}
                    >
                      {[{'label': 'Semanal', 'value': 7},
                        {'label': 'Quinzenal', 'value': 15},
                        {'label': 'Mensal', 'value': 30}
                        ].map(item => <Option value={item.value}>{item.label}</Option>)}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <Input
                      placeholder="E-mail para enviar o alerta"
                      onChange={this.handleNotificationChange}
                      prefix={<BellOutlined />}
                      name="email"
                      id="email"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          }
          <div>
            <Title level={4} style={{ marginTop: 16 }}>Dados para Integração</Title>
            <Divider />
            {Object.keys(scheduleDom).map( item => (
              <div style={{marginBottom: 16}}>
              {scheduleDom[item].map( day => (
                <div>
                  <Row gutter={[8]}>
                    <Col xs={24} style={{marginBottom: 8}}>
                      <Text strong>{day.title}</Text>
                    </Col>
                  </Row>
                  <Row gutter={[8,6]} key={day.key}>
                    <Col xs={24} sm={24} md={8}>
                      <Select
                        showSearch
                        style={{width: '100%'}}
                        id="days"
                        name="days"
                        mode="multiple"
                        placeholder="Selecione um ou mais dias"
                        onSelect={(e) => this.handleScheduleSelect('days',e,item,day.key)}
                      >
                        {weekDays.map(item => <Option value={item.value}>{item.label}</Option>)}
                      </Select>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                      <Select
                        showSearch
                        style={{width: '100%'}}
                        id="hour"
                        name="hour"
                        placeholder="Hora"
                        onSelect={(e) => this.handleScheduleSelect('hour', e, item, day.key)}
                      >
                        {Array.from(Array(24).keys()).map(item => <Option value={item}>{item}</Option>)}
                      </Select>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                      <Select
                        showSearch
                        style={{width: '100%'}}
                        id="minute"
                        name="minute"
                        placeholder="Minuto"
                        onSelect={(e) => this.handleScheduleSelect('minute', e, item, day.key)}
                      >
                        <Option value="0">00</Option>
                        <Option value="30">30</Option>
                      </Select>
                    </Col>
                    <Col xs={18} sm={18} md={6}>
                      <Select
                        showSearch
                        style={{width: '100%'}}
                        id="wait"
                        name="wait"
                        placeholder="Janela de intervalo"
                        onSelect={(e) => this.handleScheduleSelect('wait', e, item)}
                      >
                        {Array.from(Array(31).keys()).map(item => <Option value={item}>{item} {item === 1 ? 'dia' : 'dias'}</Option>)}
                      </Select>
                    </Col>
                    <Col xs={6} sm={6} md={2} style={{marginTop: 3}}>
                      <Button
                        size="small"
                        disabled={day.active}
                        shape="circle"
                        icon={<MinusOutlined />}
                        style={{marginRight: 6}}
                        onClick={() => this.removeLine(item, day.key)} />
                      <Button
                        size="small"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={() => this.duplicateLine(item)} />
                    </Col>
                  </Row>
                </div>
              ))}
              </div>
            ))}
          </div>
          <Form.Item style={{ float: 'right', marginTop: 24 }}>
            <Button
              type="primary"
              onClick={this.handleSubmit}
            >
            Inserir pesquisa
            </Button>
          </Form.Item>
        </Form>
        </div>
      </Content>
      </Layout>
    )
  }
}

export default connect(state => state)(withRouter(AddSurvey));