import { combineReducers } from 'redux';
import { 
  FETCH_USER,
  FETCH_NOTIFICATIONS,
  DISABLE_NOTIFICATION,
  FETCH_APPS,
  FETCH_ALLUSER,
  ADD_USER,
  DELETE_USER,
  FETCH_SURVEYS,
  ADD_SURVEY,
  FETCH_FILES,
  ADD_FILE,
  DELETE_FILE,
  FETCH_CLIENTS,
  ACTUAL_PATH
} from '../actions';

const isEmpty = require("is-empty");

const user = (state = {isAuthenticated: false, path: [], user: {}}, action) => {
  const { payload } = action
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(payload),
        user: payload
      }
      case ACTUAL_PATH:
        return {
          ...state,
          path: payload
        }
    default:
      return state;
  }
}

const apps = (state = [], action) => {
  const { payload } = action
  switch(action.type) {
    case FETCH_APPS:
      return [...state, ...payload]
    default:
      return state;
  }
};

const notifications = (state = { alert: false }, action) => {
  const { payload, id } = action
  switch(action.type) {
    case FETCH_NOTIFICATIONS:
      if (payload.length > 0) {
        return {
          ...state,
          alert: true,
          data: payload
        }
      }
      return state;
    case DISABLE_NOTIFICATION:
      return state.filter(item => item.id !== id);
    default:
      return state;
  }
};

const allusers = (state = { reload: false, data: [] }, action) => {
  const { payload } = action
  switch(action.type) {
    case FETCH_ALLUSER:
      if (payload.length > 0) {
        return {
          ...state,
          data: payload,
          reload: false
        }
      }
      return state;
    case ADD_USER:
      return {
        ...state,
        reload: true
      }
      case DELETE_USER:
        return {
          ...state,
          reload: true
        }
    default:
      return state;
  }
};

const files = (state = { reload: false, data: [] }, action) => {
  const { payload } = action
  switch(action.type) {
    case FETCH_FILES:
      if (payload.length > 0) {
        return {
          ...state,
          data: payload,
          reload: false
        }
      }
      return state;
    case ADD_FILE:
      return {
        ...state,
        reload: true
      }
      case DELETE_FILE:
        return {
          ...state,
          reload: true
        }
    default:
      return state;
  }
};

const surveys = (state = { reload: false, data: [] }, action) => {
  const { payload } = action
  switch(action.type) {
    case FETCH_SURVEYS:
      if (payload.length > 0) {
        return {
          ...state,
          data: payload,
          reload: false
        }
      }
      return state;
    case ADD_SURVEY:
      return {
        ...state,
        reload: true
      }
    default:
      return state;
  }
};

const clients = (state = [], action) => {
  const { payload } = action
  switch(action.type) {
    case FETCH_CLIENTS:
      return [...state, ...payload]
    default:
      return state;
  }
};

export default combineReducers({
  user,
  apps,
  allusers,
  notifications,
  files,
  surveys,
  clients
});