import React, { Component } from "react";
import "./App.less";
import { Switch, Route, Link, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Drawer, Layout, Menu, List, Space, Spin } from "antd";
import {
  BellOutlined,
  LoadingOutlined,
  SettingOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import {
  Account,
  Bases,
  Home,
  Integration,
  Login,
  NewPass,
  NewUser,
  NoMatch,
  SendBase,
  AddSurvey,
  Survey,
  Users,
  RecoverPass,
  DashboardCreatePage,
} from "./pages";
import Priv from "./components/PrivateRoute";
import { getPath, userLogout } from "./actions";

class App extends Component {
  state = {
    showNotifications: false,
    loading: true,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const { pathname } = this.props.location;
    dispatch(getPath(pathname));
    this.setState({ loading: false });
  }

  handleClick = (e) => {
    const { dispatch } = this.props;
    if (e.key === "notificacoes") {
      this.setState({ showNotifications: true });
    } else if (e.key === "sair") {
      this.props.history.push("/login");
      dispatch(userLogout());
    } else if (e.key === "qmc") {
      window.location = "https://app.inovyo.com/qmc";
    } else {
      this.props.history.push(e.key);
    }
  };

  onClose = () => {
    this.setState({ showNotifications: false });
  };

  render() {
    const { Header, Content, Footer } = Layout;
    const { SubMenu, Item } = Menu;
    const { showNotifications, loading } = this.state;
    const { notifications } = this.props;
    const { user } = this.props;

    if (loading) {
      return (
        <Space align="center" direction="vertical" style={{ width: "100%" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </Space>
      );
    } else {
      return (
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/redefinir-senha" component={RecoverPass} />
          <Route exact path="/nova-senha" component={NewPass} />
          {user.isAuthenticated === true ? (
            <Layout>
              <Header
                theme="light"
                style={{ position: "fixed", zIndex: 1, width: "100%" }}
              >
                <Link to="/">
                  <div className="logo" />
                </Link>
                <Menu
                  onClick={this.handleClick}
                  className="desktop-menu"
                  theme="light"
                  mode="horizontal"
                  defaultSelectedKeys={user.path}
                >
                  {user.user.role !== "ftp" && <Item key="/">Dashboard</Item>}
                  <Item disabled={user.user.role === "demo"} key="bases">
                    Bases
                  </Item>
                  {["admin", "customersuccess", "sale"].includes(
                    user.user.role
                  ) && <Item key="usuarios">Usuários</Item>}
                  <Item disabled={user.user.role === "demo"} key="pesquisas">
                    Pesquisas
                  </Item>
                  {user.user.role === "admin" && <Item key="qmc">QMC</Item>}
                  <SubMenu
                    style={{ float: "right" }}
                    icon={<SettingOutlined />}
                    title="Conta"
                  >
                    <Item key="conta">Configurações</Item>
                    <Item key="sair">Sair</Item>
                  </SubMenu>
                  <Item disabled style={{ float: "right" }} key="notificacoes">
                    <Badge dot={notifications.alert}>
                      <BellOutlined style={{ fontSize: "21px" }} />
                    </Badge>
                  </Item>
                </Menu>
                <Menu
                  onClick={this.handleClick}
                  className="mobile-menu"
                  theme="light"
                  mode="horizontal"
                  defaultSelectedKeys={user.path}
                  style={{ float: "right" }}
                >
                  <SubMenu icon={<MenuOutlined />}>
                    {user.user.role !== "ftp" && <Item key="/">Dashboard</Item>}
                    <Item disabled={user.user.role === "demo"} key="bases">
                      Bases
                    </Item>
                    {["admin", "customersuccess", "sale"].includes(
                      user.user.role
                    ) && <Item key="usuarios">Usuários</Item>}
                    <Item disabled={user.user.role === "demo"} key="pesquisas">
                      Pesquisas
                    </Item>
                    {user.user.role === "admin" && <Item key="qmc">QMC</Item>}
                    <SubMenu title="Conta">
                      <Item key="conta">Configurações</Item>
                      <Item key="sair">Sair</Item>
                    </SubMenu>
                  </SubMenu>
                </Menu>
              </Header>
              <Content
                className="site-layout"
                style={{ padding: "0 22px", marginTop: 56 }}
              >
                <div
                  className="site-layout-background"
                  style={{ padding: 24, marginTop: 32, minHeight: 480 }}
                >
                  <Priv exact path="/" component={Home} />
                  <Priv exact path="/bases" component={Bases} />
                  <Priv exact path="/enviar-base" component={SendBase} />
                  <Priv exact path="/pesquisas" component={Survey} />
                  <Priv
                    exact
                    path="/adicionar-pesquisa"
                    component={AddSurvey}
                  />
                  <Priv exact path="/usuarios" component={Users} />
                  <Priv exact path="/novo-usuario" component={NewUser} />
                  <Priv exact path="/conta" component={Account} />
                  <Priv exact path="/integracao" component={Integration} />
                  <Priv exact path="/dash-create" component={DashboardCreatePage} />
                </div>
              </Content>
              <Footer style={{ textAlign: "center" }}>
                © Inovyo | Experience Management. Todos os direitos reservados.
              </Footer>
              <Drawer
                title="Notificações"
                placement="right"
                closable={true}
                onClose={this.onClose}
                visible={showNotifications}
                width={320}
              >
                <List
                  size="small"
                  itemLayout="vertical"
                  dataSource={notifications.data ? notifications.data : []}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Drawer>
            </Layout>
          ) : (
            <Redirect to="/login" />
          )}
          <Route component={NoMatch} />
        </Switch>
      );
    }
  }
}

export default connect((state) => state)(withRouter(App));
