import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from "axios";
import Highlighter from 'react-highlight-words';
import { Typography, Button, Input, Space, Tooltip, Table, Menu, message, Popconfirm, Layout } from 'antd';
import { DeleteFilled, SearchOutlined, TeamOutlined, UserAddOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { delUser, getAllUsers } from '../actions';

class Users extends Component {
  state = {
    resultsOnPage: 10,
    loading: true,
    data: [],
    searchText: '',
    searchedColumn: '',
    collapsed: window.innerWidth > 768 ? false : true
  };

  componentDidMount() {
    const { dispatch, allusers } = this.props;
    const { user } = this.props.user;
    if (!['admin','customersuccess','sale'].includes(user.role)) {
      this.props.history.push('/');
    }
    if (!allusers.reload && allusers.data.length > 0) {
      this.setState({data: allusers.data, loading: false})
    } else {
      async function getQlikUsers() {
        try {
          const response = await axios.get('/api/usuarios', {
            header: {Authorization: `Bearer ${user.jwtToken}`}
          })
          return response.data
        } catch (err) {
          return err;
        }
      }
      getQlikUsers()
      .then(res => {
        dispatch(getAllUsers(res.data))
        this.setState({data: res.data, loading: false})
      })
      .catch(err => {
        message.error({ content: 'Por favor, tente novamente. Ocorreu um erro.' })
        this.setState({loading: false})
      });
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Buscar ${dataIndex === 'company' ? 'empresa' : dataIndex === 'user' ? 'usuário' : dataIndex === 'name' ? 'nome' : 'e-mail'}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Resetar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex
      ? (<Highlighter
          highlightStyle={{ backgroundColor: '#FFC400', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />)
      : (text)
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { Title } = Typography;
    const { Content, Sider } = Layout;
    const { loading, data } = this.state;
    const { user } = this.props.user;

    const tableChanges = e => {
      if (e.pageSize !== this.state.resultsOnPage) {
        this.setState({resultsOnPage: e.pageSize});    
      }
    }

    const deleteUser = id => {
      const { dispatch } = this.props;
      message.loading({ content: 'Deletando o usuário.', key: 'delete', duration: 0})
      axios.delete(`/api/deletar-usuario/${id}`, {
        header: {Authorization: `Bearer ${user.jwtToken}`}
      })
      .then(() => {
        dispatch(delUser())
        const { data } = this.state;
        const removedUser = data.filter(usr => usr.id !== id)
        this.setState({data: removedUser})
        message.success({ content: 'Usuário removido com sucesso.', key: 'delete'})
      })
      .catch(err => {
        message.error({ content: 'Por favor, tente novamente. Ocorreu um erro.', key: 'delete'})
      });
    }
    
    const handleClick = e => {
      this.props.history.push(e.key)
    };

    const columns = [
      {
        title: 'Criado em',
        dataIndex: 'createdat',
        sorter: (a, b) => a.timestamp - b.timestamp,
        defaultSortOrder: 'descend',
        width: 130,
      },{
        title: 'Empresa',
        dataIndex: 'company',
        width: 150,
        sorter: (a, b) => {return (a.company < b.company) ? -1 : (a.company > b.company) ? 1 : 0},
        ellipsis: true,
        ...this.getColumnSearchProps('company'),
      },{
        title: 'Conta',
        dataIndex: 'user',
        width: 120,
        sorter: (a, b) => {return (a.user < b.user) ? -1 : (a.user > b.user) ? 1 : 0},
        ellipsis: true,
        ...this.getColumnSearchProps('user'),
      },{
        title: 'Nome',
        dataIndex: 'name',
        width: 120,
        sorter: (a, b) => {return (a.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() < b.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()) ? -1 : (a.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() > b.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()) ? 1 : 0},
        ellipsis: true,
        ...this.getColumnSearchProps('name'),
      },{
        title: 'E-mail',
        dataIndex: 'email',
        width: 220,
        sorter: (a, b) => {return (a.email < b.email) ? -1 : (a.email > b.email) ? 1 : 0},
        ellipsis: true,
        ...this.getColumnSearchProps('email'),
      },{
        title: 'Regra',
        dataIndex: 'role',
        filters: [
          { text: 'Admininstrador', value: 'admin' },
          { text: 'Customer Success', value: 'customersuccess' },
          { text: 'Comercial', value: 'sale' },
          { text: 'Cliente', value: 'client' },
          { text: 'Demo', value: 'demo' },
          { text: 'FTP', value: 'ftp' }
        ],
        onFilter: (value, record) => record.role.indexOf(value) === 0,
        width: 100
      },{
        title: 'Ações',
        render: row => {
          return(
            <Tooltip title="Deletar usuário">
              <Popconfirm title={`Deletar o usuário ${row.email}?`} okText="Sim" cancelText="Não" placement="bottomRight" onConfirm={() => deleteUser(row.id)} disabled={!['admin','customersuccess'].includes(user.role)}>
                <Button
                  shape="circle"
                  value="small"
                  icon={<DeleteFilled />}
                  disabled={!['admin','customersuccess'].includes(user.role)}></Button>
              </Popconfirm>
            </Tooltip>
          )
        },
        fixed: 'right',
        width: 80
      }
    ];
    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed} width={200}>
          <Menu
            onClick={handleClick}
            style={{ height: '100%' }}
            defaultSelectedKeys={['usuarios']}
          >
          <Menu.Item key="usuarios" icon={window.innerWidth < 769 && <TeamOutlined />}>Todos os Usuários</Menu.Item>
          {['admin','customersuccess'].includes(user.role) && <Menu.Item key="novo-usuario" icon={window.innerWidth < 769 && <UserAddOutlined />}>Criar usuário</Menu.Item>}
          </Menu>
        </Sider>
        <Content className="site-layout" style={{ padding: 0, margin: 0, minHeight: 440, background: 'white' }}>
          <div className="site-layout-background" style={{ padding: 16, paddingTop: 0 }}>
            <Title level={4}>
            {window.innerWidth < 769 && React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}Usuários</Title>
            <Table
              responsive={true}
              columns={columns}
              dataSource={data}
              pagination={{ pageSize: this.state.resultsOnPage, showSizeChanger: true, size: 'small' }}
              onChange={(e) => tableChanges(e)}
              scroll={{ y: 420 }}
              loading={loading} />
          </div>
        </Content>
      </Layout>
    )
  }
}

export default connect(state => state)(withRouter(Users));
